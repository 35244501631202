<template>
  <div>
    <div class="grid grid-cols-8 gap-6" v-if="loaded && !order.error">
      <div class="col-span-5">
        <h1>Order – {{ ordernr }}</h1>
        <span>Status: {{ order.status | capitalize }}</span>
        <h2>Products</h2>
        <div class="pb-6">
          <div
            class="grid grid-cols-5 gap-6"
            v-for="(item, index) in products"
            :key="`${index}-products`"
          >
            <div class="col-span-2">{{ item.name }}</div>
            <div>{{ item.unitPrice.value | currency }}</div>
            <div>{{ item.quantity }}</div>
            <div>{{ item.totalAmount.value | currency }}</div>
          </div>
        </div>
        <h2>Shipping</h2>
        <div>
          <div
            class="grid grid-cols-5 gap-6"
            v-for="(item, index) in shipping"
            :key="`${index}-shipping`"
          >
            <div class="col-span-4">{{ item.name }}</div>
            <div>{{ item.totalAmount.value | currency }}</div>
          </div>
        </div>
        <hr />
        <div class="grid grid-cols-5 gap-6">
          <div class="col-start-5">
            {{ totals | currency }}
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <template v-if="order.status === 'created'">
          <h1>Checkout</h1>
          <button class="bg-black text-white rounded-full outline-none px-2">
            <a target="_blank" :href="order._links.checkout.href"
              >Proceed to payment</a
            >
          </button>
        </template>
        <template v-else>
          <h1>Tracking</h1>
          <template v-if="order.status === 'paid'">
            Once your order has been shipped, you can find the carrier its
            tracking information on this page (if applicable).
          </template>
          <template v-if="order._embedded.shipments.length > 0">
            Your order has been send with
            {{ order._embedded.shipments[0].tracking.carrier }}. Your tracking
            code is: {{ order._embedded.shipments[0].tracking.code }}
            <div class="pt-3">
              <button
                class="bg-black text-white rounded-full outline-none px-2"
              >
                <a
                  target="_blank"
                  :href="order._embedded.shipments[0].tracking.url"
                  >Track order</a
                >
              </button>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div v-else-if="order.error">
      {{ order.error }}
    </div>
    <div v-else>Loading order ...</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Order",
  props: ["ordernr", "id"],
  metaInfo: {
    title: "Order",
  },
  data() {
    return {
      loaded: false,
      order: [],
    };
  },
  created() {
    if (this.ordernr === undefined || this.id === undefined) {
      console.log("404");
    }

    this.$api
      .get(`order/${this.ordernr}/${this.id}`)
      .then((response) => {
        this.order = response.data;
        this.$store.dispatch("emptyCart");
        this.loaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    products() {
      return this.order.lines.filter((item) => item.type === "physical");
    },
    shipping() {
      return this.order.lines.filter((item) => item.type === "shipping_fee");
    },
    totals() {
      return this.order.amount.value;
    },
  },
};
</script>